<template>
  <div class="">
    <b-card class="mt-1">
      <div class="row">
        <div class="col-12">
          <h2>All Cost Code Buckets Budget VS Total</h2>
        </div>

        <div class="col-md-6 col-xl-6">
          <h5>Select Bucket Template</h5>
          <multiselect v-model="selectedBucket" @input="getBudgets()" placeholder="Search" tag-placeholder="Search" track-by="cc_bucketid" :options="buckets" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="false" label="name" :preselect-first="false">
          </multiselect>
        </div>
      </div>

      <div id="bucketChart" class="mt-3">
        <div id="chartdiv" style="height: 500px;"></div>
      </div>
      <rotate-square2 id="loading-gif" v-show="loading"></rotate-square2>
    </b-card>
  </div>
</template>

<script src="//www.amcharts.com/lib/4/plugins/responsive/responsive.min.js"></script>
<script>
import Vue from 'vue'
import {ClientTable, Event} from 'vue-tables-2'
Vue.use(ClientTable)
import Multiselect from 'vue-multiselect'
import {RotateSquare2} from 'vue-loading-spinner'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//import am4themes_material from "@amcharts/amcharts4/themes/material";

am4core.useTheme(am4themes_animated);
//am4core.useTheme(am4themes_material);

export default {
  name: "CostCodeBuckets",
  components: {
    Multiselect,
    ClientTable,
    RotateSquare2,
  },
  data : function () {
    return {
      projectData: [],
      afeData: [],
      projectids: [],
      afes: [],
      chainData: [],
      bucketName: "",
      selectedBucket: "",
      budgets: [],
      theme: 'bootstrap4',
      template: 'default',
      bucket: [],
      buckets: [],
      editBucket: false,
      loading: false,
    }
  },
  methods: {
    async getCost(row) {
      return this.$http.get('/cc_bucket/get/cost/' + encodeURIComponent(row.cc1) + '/' + encodeURIComponent(row.cc2) + '/' + encodeURIComponent(row.cc3) + '/' + encodeURIComponent(row.pwcaid))
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log(error)
      })
    },
    async getBucketCost() {
      this.loading = true;
      for (var i = 0; i < this.selectedBucket.cc_group.length; i++) {
        var totalBudget = 0;
        var totalCost = 0;
        for (var y = 0; y < this.selectedBucket.cc_group[i].cc_group.length; y++) {
          console.log(this.selectedBucket.cc_group[i].cc_group[y].service_ccid)
          //this.selectedBucket.cc_group[i].cc_group[y].total = 30.00
          const cost = await this.getCost(this.selectedBucket.cc_group[i].cc_group[y])
          this.selectedBucket.cc_group[i].cc_group[y].total = cost

          totalCost += cost.total;

          var filtered = this.budgets.filter(x=>x.cc1_id == this.selectedBucket.cc_group[i].cc_group[y].cc1id && x.cc2_id == this.selectedBucket.cc_group[i].cc_group[y].cc2id && x.cc3_id == this.selectedBucket.cc_group[i].cc_group[y].cc3id)
          if(filtered.length > 0){
            this.selectedBucket.cc_group[i].cc_group[y].budget = filtered[0].budget;
            totalBudget += filtered[0].budget;
          }
          else {
            this.selectedBucket.cc_group[i].cc_group[y].budget = 0;
          }
        }

        this.selectedBucket.cc_group[i].totalBudget = totalBudget;
        this.selectedBucket.cc_group[i].totalCost = totalCost;
      }

      this.bucket = this.selectedBucket;
      this.loading = false;
    },
    getBuckets(){
      this.$http.get('/cc_bucket/get/')
      .then((response) => {
        this.buckets = response.data.result.filter(x=>x.status == 1);
        
      })
      .catch((error) => {
        console.log(error)
      })
    },
    getBudgets(){
      this.$http.get('/cc_budget/get/')
      .then(response => {
        this.budgets = response.data.result;
        this.loadChart(this.selectedBucket);
      })
      .catch(error => {
      })
    },
    async loadChart(bucket){
     bucket.cc_group = JSON.parse(bucket.cc_group); 
      this.loading = true;
      const that = this;

      var chart = am4core.create("chartdiv", am4charts.XYChart3D);
      chart.colors.list = [
        am4core.color("#4dbd74"),
        am4core.color("#c0504e"),
        am4core.color("#444444"),
      ];

      var chartData = [];

      var chartStackedData = [];
      for (var i = 0; i < bucket.cc_group.length; i++) {
        var totalBudget = 0;
        var totalCost = 0;
        for (var y = 0; y < bucket.cc_group[i].cc_group.length; y++) {
          const cost = await that.getCost(bucket.cc_group[i].cc_group[y])
          totalCost += cost.total;
          var filtered = that.budgets.filter(x=>x.cc1_id == bucket.cc_group[i].cc_group[y].cc1id && x.cc2_id == bucket.cc_group[i].cc_group[y].cc2id && x.cc3_id == bucket.cc_group[i].cc_group[y].cc3id)
          if(filtered.length > 0){
            totalBudget += filtered[0].budget;
          }
          else {
            totalBudget += 0
          }
        }

        chartData.push({
          "bucket": bucket.cc_group[i].name,
          "cost": this.formatPrice(totalCost),
          "budget": this.formatPrice(totalBudget),
          "difference": this.formatPrice(totalCost - totalBudget),
        })

      }

      chart.legend = new am4charts.Legend();

      am4core.ready(function() {
        chart.data = chartData;
        console.log(chartData)

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "bucket";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Dollars";
        valueAxis.min = 0;
        valueAxis.renderer.labels.template.adapter.add("text", function(text) {
          return "$" + text;
        });

        var series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.dataFields.valueY = "budget";
        series2.dataFields.categoryX = "bucket";
        series2.name = "Total Budget";
        series2.clustered = true;
        series2.columns.template.tooltipText = "{category} Total Budget: [bold]{budget}[/] - Total Cost: [bold]{cost}[/]  = [bold]{difference}[/]";
        series2.tooltip.autoTextColor = false;
        series2.tooltip.label.fill = am4core.color("#FFFFFF");

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "cost";
        series.dataFields.categoryX = "bucket";
        series.name = "Total Cost";
        series.clustered = true;
        series.columns.template.tooltipText = "{category} Total Budget: [bold]{budget}[/] - Total Cost: [bold]{cost}[/]  = [bold]{difference}[/]";
        series.columns.template.fillOpacity = 0.9;
        series.tooltip.autoTextColor = false;
        series.tooltip.label.fill = am4core.color("#FFFFFF");

        var series3 = chart.series.push(new am4charts.ColumnSeries());
        series3.dataFields.valueY = "difference";
        series3.dataFields.categoryX = "bucket";
        series3.name = "Difference";
        series3.clustered = true;
        series3.columns.template.tooltipText = "{category} Total Budget: [bold]{budget}[/] - Total Cost: [bold]{cost}[/]  = [bold]{difference}[/]";
        series3.tooltip.autoTextColor = false;
        series3.tooltip.label.fill = am4core.color("#FFFFFF");


        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.filePrefix = "Cost Bucket Chart Export";
        chart.exporting.menu.items = [{
          "label": "Export <i class='fa fa-export'></i>",
          "menu": [
            {
              "label": "Image",
              "menu": [
                { "type": "png", "label": "PNG" },
                { "type": "jpg", "label": "JPG" },
                { "type": "gif", "label": "GIF" },
                { "type": "svg", "label": "SVG" },
              ]
            }, {
              "label": "Data",
              "menu": [
                { "type": "json", "label": "JSON" },
                { "type": "csv", "label": "CSV" },
                { "type": "xlsx", "label": "XLSX" }
              ]
            }, {
              "label": "Print", "type": "print"
            }
          ]
        }];


      }); // end am4core.ready()
      this.loading = false;
    },
  },
  mounted() {
    this.getBuckets();
  }
}
</script>

<style lang="scss">
  #bucketChart {
    position: relative;
    .amcharts-amexport-menu-level-0.amcharts-amexport-top {
      top: -50px;
      bottom: auto;
      right: 15px;
    }
    .amcharts-amexport-item.amcharts-amexport-item-level-0 {
      width: 100px;
      height: inherit;
      padding: 0.5em;
    }
    .amcharts-amexport-item {
      border: none;
      background: #4dbd74;

      a {
        color: #fff;
      }
    }
  }
  .danger {
    color: #db1616;
  }

  .green {
    color: #4dbd74;
  }
  table.bucket-table td,
  table.bucket-table tr, {
    vertical-align: middle;
  }
  .well-datepicker {
    input {
      width: 100%;
      font-size: 100%;
      padding: 0.35rem;
    }
  }
  .rt-multi {
    width: 100% !important;
    .checkboxLayer {
      margin-top: 10px;
      width: 100%;
    }
    .btn-select {
      width: 100% !important;
      display: block !important;
      padding: 6px 40px !important;
    }
  }
  .disabled-dp {
    input {
      background: #e6e6e6;
    }
  }
  #dataTable.wellTable {
    table th:nth-child(3),
    table tr:nth-child(3){
      width: 110px;
    }
  }
  .dropdown-chev {
    position: absolute;
    top: 11px;
    right: 15px;
  }
  .vue-simple-suggest.designed .input-wrapper input {
    padding: 7px;
  }

  .afe-status-table td{
    width: 120px !important;
  }
  #loading-gif {
    z-index: 9999;
    position: absolute;
    top: 30%;
    right: 50%;
  }

  #chain-data {
    .VueTables__search-field {
      margin-top: 0.5em;
      label {
        display: none;
      }
    }

    @media screen and (min-width: 1200px){
      .table-responsive {
        overflow: hidden !important;
      }
    }

    div table thead tr th {
      background-color: #444;
      color: #fff;
      font-size: 1.2em;
    }
    td {
      vertical-align: middle;
    }
    div table thead tr th:first-child {
      //width: 5%;
    }
    .chain-data-child-table{
        tbody {
          //background-color: rgba(63, 233, 38, 0.09);
        }

        .VuePagination {
          margin-top: 2em;
        }

        th {
          background: #7d7d7d !important;
          color: #fff !important;
          font-size: 1em !important;
        }
        th {
          width: inherit !important;
        }
        th:first-child {
          //width: 5% !important;
        }

        .VueTables__child-row > td {
          padding: 0;
      }
    }
    .parent th {
      background: #444;
      color: #fff;
    }

    .checkslots {
      text-align: center;
      margin: 0 auto;
      display: block;
      margin-top: 2px;
      font-size: 4em;
    }
    .table td {
      padding: 0.55rem;
    }
    .VueTables__heading {
      text-align: left;
    }
    .VuePagination {
      text-align: center;
      justify-content: center;
    }

    .VueTables__search-field {
      display: flex;
    }
    .VueTables__search-field input {
      margin-left: 0.25rem;
    }

    .VueTables__limit-field {
      display: flex;
    }

    .VueTables__limit-field select {
      margin-left: 0.25rem !important;
    }

    .VueTables__table th {
      text-align: left;
    }

    .VueTables__child-row-toggler {
      width: 16px;
      height: 16px;
      line-height: 18px;
      background: #4dbd74;
      display: block;
      margin: auto;
      text-align: center;
      padding: 6px !important;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      line-height: 20px;
      color: #fff;
      font-weight: bold;
    }

    .VueTables__child-row-toggler--open {
      line-height: 17px !important;
    }

    .VueTables__child-row-toggler--closed::before {
      content: "\f054";
      font-weight: 400 !important;
      font-family: "Font Awesome 5 Pro" !important;
    }

    .VueTables__child-row-toggler--open::before {
      font-family: "Font Awesome 5 Pro";
      font-weight: 400 !important;
      content: "\f078" !important;
    }
  }
</style>
